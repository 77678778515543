import React from 'react';

import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pb, pt, px, Padding } from 'styled-components-spacing';

import Card from '@nib/card';
import {
  FamilyGraphicIcon,
  AssessmentGraphicIcon,
  ClockGraphicIcon,
  PriceGraphicIcon,
  SingleGraphicIcon,
  CoupleGraphicIcon,
} from '@nib/icons';
import { Box, Section, Stack, Tiles } from '@nib/layout';

import { PrimaryButton } from '@nib-components/button';
import colors from '@nib-components/colors';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';

import Layout from '../components/Layout';
import { urlConstants } from '../constructs/constants';
import Piggyback300w from '../img/orbit/piggyback2-300x200.jpg';
import Piggyback600w from '../img/orbit/piggyback2-600x560.jpg';
import metrics from '../metrics';
import { StyledCard } from '../components/styledComponents';

const title = 'Orbit Medical Insurance';
const metaDescription = '';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem1'
    'gridItem3'
    'gridItem2';
  ${pb(6)};
  ${breakpoint('lg')`
    ${pb(8)};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'gridItem1 gridItem3'
      'gridItem2 gridItem3'
  `};
`;

const GridItem1 = styled.div`
  grid-area: gridItem1;
  ${breakpoint('lg')`
    ${px(4)};
    ${pb(0)};
  `};
`;

const GridItem2 = styled.div`
  grid-area: gridItem2;
  text-align: center;
  ${pt(4)};
  ${breakpoint('lg')`
    text-align: left;
    ${px(4)};
  `};
`;

const GridItem3 = styled.div`
  grid-area: gridItem3;
  display: grid;
  ${breakpoint('lg')`
    min-height: 475px;
  `};
`;

const ValueImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;

  ${breakpoint('lg')`
    height: 100%;
    width: auto;
    text-align: left;
  `};
`;

const ValueImage = styled.img`
  height: auto;
  width: 100%;
  ${pt(4)};

  ${breakpoint('lg')`
    position: absolute;
    ${pt(0)};
    ${(props) =>
      !props.thin
        ? css`
            height: 100%;
            width: auto;
          `
        : css`
            width: 100%;
          `}
  `};
`;

const ValueSection = (): JSX.Element => (
  <Section>
    <Grid>
      <GridItem1>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h1">
            Orbit Insurance: Simple, smart and value for money
          </Heading>
          <Copy measure={false}>
            Decided to stay in New Zealand? nib has teamed up with Orbit Protect to offer health
            insurance to Orbit’s eligible<sup>1</sup> customers.
          </Copy>
          <Copy measure={false}>
            We offer cover for the big stuff like cancer and surgery as well as everyday health
            costs like trips to the doctor & prescriptions, dental, optical and more.
          </Copy>
        </Stack>
      </GridItem1>
      <GridItem2>
        <PrimaryButton href={urlConstants.getQuickQuote()}>Explore options</PrimaryButton>
      </GridItem2>
      <GridItem3>
        <ValueImageWrapper>
          <picture>
            <source media="(max-width:640px)" srcSet={`${Piggyback300w}`} />
            <ValueImage src={Piggyback600w}></ValueImage>
          </picture>
        </ValueImageWrapper>
      </GridItem3>
    </Grid>
    <Features />
  </Section>
);

const BoxWrapper = styled(Box)`
  box-sizing: border-box;
  text-align: center;
`;

interface FeatureTileProps {
  icon: any;
  title: string;
}

const FeatureTile = ({ icon: Icon, title }: FeatureTileProps): JSX.Element => (
  <BoxWrapper
    padding={5}
    paddingVertical={{ lg: 6 }}
    height="100%"
    style={{ boxShadow: `${colors.sneezy} 0px 4px 8px 0px` }}
  >
    <Icon size="xl" fill="trueGreen" />
    <Padding top={4}>
      <Box textAlign="center">
        <Heading size={4} component="h3" style={{ fontWeight: 'normal' }}>
          {title}
        </Heading>
      </Box>
    </Padding>
  </BoxWrapper>
);

const Features = (): JSX.Element => (
  <div>
    <Box display="flex" justifyContent="center" marginBottom={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
        Why it's worth it?
      </Heading>
    </Box>
    <Padding bottom={5}>
      <Tiles columns={{ xs: 1, md: 2, lg: 4 }} space={{ xs: 4, md: 6 }}>
        <FeatureTile icon={FamilyGraphicIcon} title="Over 1 million Kiwis and Aussies covered" />
        <FeatureTile
          icon={AssessmentGraphicIcon}
          title="Fast track access to your healthcare treatment"
        />
        <FeatureTile icon={ClockGraphicIcon} title="Tailor your cover to suit your needs" />
        <FeatureTile icon={PriceGraphicIcon} title="Simple to join, value for money" />
      </Tiles>
    </Padding>
  </div>
);

const QuoteSection = (): JSX.Element => (
  <Section background="sageGreen60">
    <Stack space={4}>
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <Heading
          color="trueGreen"
          size={{ sm: 4, md: 3, lg: 2 }}
          component="h2"
          style={{ textAlign: 'center' }}
        >
          Picking the right health insurance is all about making sure it fits with your lifestyle.
        </Heading>
      </Box>
      <Copy measure={false}>
        With cover for everyday items like dental, optical, chiro and acupuncture, through to cover
        for those unexpected events, like cancer treatment, surgery costs and after surgery care,
        nib has the cover to suit you.
      </Copy>
      <Tiles columns={{ xs: 1, md: 3 }} space={{ xs: 4, lg: 6 }} flex>
        <StyledCard icon={SingleGraphicIcon} align="center">
          <Card.Content title="Singles Health Insurance">
            <Copy>
              Ranging from just the everyday to comprehensive hospital insurance, you want quality
              cover that suits your needs.
            </Copy>
          </Card.Content>
          <Card.Footer>
            <Copy>
              From $20.46 weekly<sup>3</sup>
            </Copy>
            <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
          </Card.Footer>
        </StyledCard>
        <StyledCard icon={CoupleGraphicIcon} align="center">
          <Card.Content title="Couples Health Insurance">
            <Copy>
              You and your partner would like to be covered for a range of services, so you're
              looking for the best cover to suit your needs.
            </Copy>
          </Card.Content>
          <Card.Footer>
            <Copy>
              From $47.71 weekly<sup>4</sup>
            </Copy>
            <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
          </Card.Footer>
        </StyledCard>
        <StyledCard icon={FamilyGraphicIcon} align="center">
          <Card.Content title="Families Health Insurance">
            <Copy>
              You've started a family but there's room for more! You'd like to be covered for the
              everyday and birth-related services.
            </Copy>
          </Card.Content>
          <Card.Footer>
            <Copy>
              From $72.63 weekly<sup>5</sup>
            </Copy>
            <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
          </Card.Footer>
        </StyledCard>
      </Tiles>
    </Stack>
  </Section>
);

const DocumentSection = (): JSX.Element => (
  <Section>
    <Box padding={4} borderRadius="standard">
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
        Important things to know
      </Heading>
      <Copy measure={false} component="div">
        <ol style={{ paddingLeft: '1rem' }}>
          <li>
            <p>Eligibility for nib health cover</p>A person is eligible for Standard Hospital and
            Premium Hospital cover if they are:
            <ul>
              <li>a citizen or permanent resident of New Zealand living in New Zealand; or</li>
              <li>
                an Australian citizen or permanent resident who has lived, or intends to live, in
                New Zealand for two years or more; or
              </li>
              <li>
                permanently employed in New Zealand and hold a current New Zealand work permit which
                has been issued for at least two years with at least 12 months remaining; or
              </li>
              <li>
                entitled to publicly funded health and disability services provided by the New
                Zealand Government.
              </li>
              <li>
                A person is eligible for Standard Everyday and Premium Everyday cover if they are
                lawfully residing in New Zealand.
              </li>
            </ul>
            <br />
          </li>
          <li>
            This overview is not a policy document. It is an outline of the main features and
            benefits of the eligible nib covers. A full explanation of the benefits, exclusions and
            applicable waiting limits are contained in the policy documents. The policy documents
            are available from our <Link href="/am-i-covered">what am I covered for</Link> webpage .
          </li>
          <li>
            Based on a female, 27 years old, non-smoker with nib Standard Hospital and Standard
            Everyday cover with a $500 annual excess for the Standard Hospital cover. Price includes
            our 4% direct debit discount.
          </li>
          <li>
            Based on a couple, with two adults both 30 years old and non-smokers with a nib Standard
            Hospital and Premium Everyday cover with a $500 annual excess for the Standard Hospital
            cover. Price includes our 4% direct debit discount.
          </li>
          <li>
            Based on a family of two adults (both 35 years old and non-smokers) and two kids (under
            21) with nib Premium Hospital and Premium Everyday cover with a $500 annual excess for
            the Premium Hospital cover. Price includes our 4% direct debit discount.
          </li>
        </ol>
      </Copy>
    </Box>
  </Section>
);

const schema = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  '@id': 'WebPage',
  identifier: 'http://www.nib.co.nz/orbit',
  url: 'http://www.nib.co.nz/orbit',
  description:
    'Decided to stay in New Zealand? nib has teamed up with Orbit Protect to offer health insurance to Orbit’s eligible customers.',
  name: ['Private Health Insurance & Medical Insurance | nib'],
  isPartOf: 'https://www.nib.co.nz',
};
const JSONschema = JSON.stringify(schema);

const OrbitPage = (): JSX.Element => (
  <Layout>
    <Helmet>
      <title>{title} | nib</title>
      <meta name="description" content={metaDescription} />
      <script type="application/ld+json">{JSONschema}</script>
    </Helmet>
    <ValueSection />
    <QuoteSection />
    <DocumentSection />
  </Layout>
);

export default metrics({ pageName: 'orbit' })(OrbitPage);
